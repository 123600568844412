import React, { lazy } from "react";

import MainLayout from "layout/MainLayout";
import NotFound from "pages/misc/notFound";
import { CountryDetail } from "pages/risk/country/detail";
import ProtectedRoute from "./ProtectedRoute";
import Loadable from "ui-component/Loadable";
import DefaultCountrySelector from "pages/risk/country/detail/defaultCountrySelector";
import CompareCountries from "pages/risk/country/compareCountries";
import UserAccount from "pages/userAccount";

// lazy loaded routes
const RiskHeatMap = Loadable(lazy(() => import("pages/risk/country")));
const CompanyRisk = Loadable(lazy(() => import("pages/risk/company")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: (
        <ProtectedRoute>
          <RiskHeatMap />
        </ProtectedRoute>
      ),
    },
    {
      path: "user/account/",
      element: (
        <ProtectedRoute>
          <UserAccount />
        </ProtectedRoute>
      ),
    },

    {
      path: "country-risk/",
      element: (
        <ProtectedRoute>
          <DefaultCountrySelector />
        </ProtectedRoute>
      ),
    },
    {
      path: "country-risk/:countryCode",
      element: (
        <ProtectedRoute>
          <CountryDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: "company-risk/",
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute>
              <CompanyRisk />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "compare-countries/",
      element: (
        <ProtectedRoute>
          <CompareCountries />
        </ProtectedRoute>
      ),
    },

    {
      path: "*",
      element: <NotFound />,
    },
  ],
};
export default MainRoutes;
