/* eslint-disable no-unused-vars */
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchMultipleCountries from "./search";
import config from "config";
import { useEffect, useMemo, useState } from "react";
import { GET_COUNTRY_LIST } from "redux/countryRisk/action";

// import CompareCountriesTreeView from "./treeView";
// import CompareCountriesTableView from "./tableView";
import CustomCard from "ui-component/card";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";
import { BASE_COMPARE_COUNTRIES } from "redux/compareCountries/action";
import { SET_COUNTRIES_STATISTICS } from "redux/compareCountries/action";
import CompareCountriesPivotTableView from "./result/pivotTableView";
import InsightView from "./result/InsightView";
import RiskLevelCellRenderer from "./result/tableView/riskLevelCellRenderer";
import SentimentCellRenderer from "./result/tableView/sentimentCellRenderer";
import { hrCategoryNameList, sectorNameList } from "utils/constants";
import { capitalizeAndRemoveUnderscore, objectIsEmpty } from "utils/helper";
import CompareCountriesResult from "./result";

const CompareCountries = () => {
  const dispatch = useDispatch();

  const { countryList } = useSelector((state) => state.countryRisk);

  const { countryDetail } = useSelector((state) => state.compareCountries);

  let loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COMPARE_COUNTRIES,
      requestName: SET_COUNTRIES_STATISTICS,
    })
  );
  const [searchAccordionState, setSearchAccordionState] = useState(true);

  useEffect(() => {
    if (countryList?.length === 0) {
      if (countryList.length === 0) dispatch({ type: GET_COUNTRY_LIST });
    }
  }, []);

  return (
    <Box
      sx={{
        height: "auto",
        minHeight: `calc( 100vh - ${config.headerHeight})`,
        width: "100%",
        p: 2,
      }}
    >
      <Box sx={{ height: "auto" }}>
        <SearchMultipleCountries
          countryList={countryList}
          searchAccordionState={searchAccordionState}
          setSearchAccordionState={setSearchAccordionState}
        />
      </Box>
      {(countryDetail.length > 0 || loading) && (
        <Box
          sx={{
            height: searchAccordionState
              ? `calc(100vh - ${config.headerHeight} - 270px)`
              : `calc(100vh - ${config.headerHeight} - 90px)`,
          }}
        >
          <CompareCountriesResult loading={loading} />
        </Box>
      )}
    </Box>
  );
};

export default CompareCountries;
