export const BASE_COMAPNY_ACTION_NAME = "@company_risk";
export const GET_TWITTER_DATA = BASE_COMAPNY_ACTION_NAME + "/GET_TWITTER_DATA";
export const SET_TWITTER_DATA = BASE_COMAPNY_ACTION_NAME + "/SET_TWITTER_DATA";
export const RESET_TWITTER_DATA =
  BASE_COMAPNY_ACTION_NAME + "/RESET_TWITTER_DATA";

export const GET_NGO_REPORT_DATA =
  BASE_COMAPNY_ACTION_NAME + "/GET_NGO_REPORT_DATA";
export const SET_NGO_REPORT_DATA =
  BASE_COMAPNY_ACTION_NAME + "/SET_NGO_REPORT_DATA";
export const RESET_NGO_REPORT_DATA =
  BASE_COMAPNY_ACTION_NAME + "/RESET_NGO_REPORT_DATA";

export const GET_NEWS_ARTICLES =
  BASE_COMAPNY_ACTION_NAME + "/GET_NEWS_ARTICLES";
export const SET_NEWS_ARTICLES =
  BASE_COMAPNY_ACTION_NAME + "/SET_NEWS_ARTICLES";

export const GET_ARTICLE_SOURCE =
  BASE_COMAPNY_ACTION_NAME + "/GET_ARTICLE_SOURCE";
export const SET_ARTICLE_SOURCE =
  BASE_COMAPNY_ACTION_NAME + "/SET_ARTICLE_SOURCE";

export const GET_COMMONLY_USED_KEYWORD =
  BASE_COMAPNY_ACTION_NAME + "/GET_COMMONLY_USED_KEYWORD";
export const SET_COMMONLY_USED_KEYWORD =
  BASE_COMAPNY_ACTION_NAME + "/SET_COMMONLY_USED_KEYWORD";

export const GET_NEWS_VOLUME = BASE_COMAPNY_ACTION_NAME + "/GET_NEWS_VOLUME";
export const SET_NEWS_VOLUME = BASE_COMAPNY_ACTION_NAME + "/SET_NEWS_VOLUME";

export const GET_NEWS_ENTITIES =
  BASE_COMAPNY_ACTION_NAME + "/GET_NEWS_ENTITIES";
export const SET_NEWS_ENTITIES =
  BASE_COMAPNY_ACTION_NAME + "/SET_NEWS_ENTITIES";

export const GET_COMPANY_INFO = BASE_COMAPNY_ACTION_NAME + "/GET_COMPANY_INFO";
export const CLEAR_COMPANY_INFO =
  BASE_COMAPNY_ACTION_NAME + "/CLEAR_COMPANY_INFO";

export const GET_COMPANY_AUTOCOMPLETE =
  BASE_COMAPNY_ACTION_NAME + "/GET_COMPANY_AUTOCOMPLETE";
export const SET_COMPANY_AUTOCOMPLETE =
  BASE_COMAPNY_ACTION_NAME + "/SET_COMPANY_AUTOCOMPLETE";

export const GET_COMPANY_NEWS = BASE_COMAPNY_ACTION_NAME + "/GET_COMPANY_NEWS";
export const SET_COMPANY_NEWS = BASE_COMAPNY_ACTION_NAME + "/SET_COMPANY_NEWS";

export const GET_COMPANY_DETAIL =
  BASE_COMAPNY_ACTION_NAME + "/GET_COMPANY_DETAIL";
export const SET_COMPANY_DETAIL =
  BASE_COMAPNY_ACTION_NAME + "/SET_COMPANY_DETAIL";

export const SET_COMPANY_SEARCH_FIELD =
  BASE_COMAPNY_ACTION_NAME + "/SET_COMPANY_SEARCH_FIELD";

export const DOWNLOAD_COMPANY_REPORT =
  BASE_COMAPNY_ACTION_NAME + "/DOWNLOAD_COMPANY_REPORT";
