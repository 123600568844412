/* eslint-disable no-debugger */
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./action";
import * as api from "./api";
import * as helperActionCreators from "redux/loadingAndError/actionCreators";
import { CLEAR_FAILED_REQUESTS } from "redux/loadingAndError/action";

function* handleNGOReportData(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_NGO_REPORT_DATA,
      })
    );
    //logic
    const ngoArticleData = yield call(
      api.getNGOReportData,
      action.companyName,
      action.publishedStartDate,
      action.publishedEndDate,
      action.categories
    );
    const actionFrequencyAndMocingAverage = yield call(
      api.getNGOActionGrequencyAndMovingAverage,
      action.companyName,
      action.publishedStartDate,
      action.publishedEndDate,
      action.categories
    );
    const payload = {
      articleCount: ngoArticleData.count,
      articleList: ngoArticleData.articleList,
      hrIssueCategory: ngoArticleData.hrIssueCategory,
      sentiment: ngoArticleData.sentiment,
      volume: ngoArticleData.volume,
      wordFrequency: ngoArticleData.wordFrequency,
      actionFrequency: actionFrequencyAndMocingAverage.action_frequency,
      actionFrequencyMovingAverage: actionFrequencyAndMocingAverage.moving_avg,
    };
    yield put({ type: actionTypes.SET_NGO_REPORT_DATA, payload: payload });

    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_NGO_REPORT_DATA,
      })
    );
    // throw " NGO API threw Error";
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_NGO_REPORT_DATA,
        error: error,
      })
    );
    // yield put({ type: "FETCH_FAILED", message: e.message });
  }
}

function* handleAutoCompleteData(action) {
  try {
    const response = yield call(api.getAutoCompleteData, action.companyName);
    yield put({
      type: actionTypes.SET_COMPANY_AUTOCOMPLETE,
      payload: response,
    });
  } catch (e) {
    // yield put({ type: "FETCH_FAILED", message: e.message });
  }
}

function* handleGetCompanyDetail(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_DETAIL,
      })
    );
    //logic
    let response = yield call(api.getCompanyDetail, action.companyName);
    if (response === "") {
      response = {};
    }
    yield put({
      type: actionTypes.SET_COMPANY_DETAIL,
      payload: response,
    });

    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_DETAIL,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_DETAIL,
        error: error,
      })
    );
  }
}
function* handleGetCompanyNews(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_NEWS,
      })
    );

    const newsArtcileData = yield call(
      api.getCompanyNews,
      action.companyName,
      action.categories,
      action.publishedStartDate,
      action.publishedEndDate,
      action.japaneseSearch,
      action.sectors,
      action.country
    );
    const payload = {
      articleCount: newsArtcileData.count,
      articleList: newsArtcileData.articleList,
      hrIssueCategory: newsArtcileData.hrIssueCategory,
      sentiment: newsArtcileData.sentiment,
      volume: newsArtcileData.volume,
      wordFrequency: newsArtcileData.wordFrequency,
    };
    yield put({
      type: actionTypes.SET_COMPANY_NEWS,
      payload: payload,
    });

    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_NEWS,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.SET_COMPANY_NEWS,
        error: error,
      })
    );
  }
}

function* handleCompanySearch(action) {
  try {
    yield put({
      type: CLEAR_FAILED_REQUESTS,
    });

    // company details
    yield put({
      type: actionTypes.GET_COMPANY_DETAIL,
      companyName: action.companyName,
    });
    // company news
    yield put({
      type: actionTypes.GET_COMPANY_NEWS,
      companyName: action.companyName,
      publishedStartDate: action.publishedStartDate,
      publishedEndDate: action.publishedEndDate,
      categories: action.categories,
      sectors: action.sectors,
      country: action.country,
      japaneseSearch: action.japaneseSearch,
    });
    // // news volume
    // yield put({
    //   type: actionTypes.GET_NEWS_VOLUME,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   titleSentiment: action.titleSentiment,
    //   categories: action.categories,
    // });

    // // news entities
    // yield put({
    //   type: actionTypes.GET_NEWS_ENTITIES,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   titleSentiment: action.titleSentiment,
    //   categories: action.categories,
    // });

    // // news articles top source
    // yield put({
    //   type: actionTypes.GET_ARTICLE_SOURCE,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   titleSentiment: action.titleSentiment,
    //   categories: action.categories,
    // });

    // // news articles commonly used keyword
    // yield put({
    //   type: actionTypes.GET_COMMONLY_USED_KEYWORD,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   titleSentiment: action.titleSentiment,
    //   categories: action.categories,
    // });

    // get  ngo report
    yield put({
      type: actionTypes.GET_NGO_REPORT_DATA,
      companyName: action.companyName,
      publishedStartDate: "2010-01-01T00:00:00.000Z",
      publishedEndDate: new Date().toISOString(),
      categories: action.categories,
    });

    // // get  get twitter data
    // yield put({
    //   type: actionTypes.GET_TWITTER_DATA,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   categories: action.categories,
    // });

    // // news articles
    // yield put({
    //   type: actionTypes.GET_NEWS_ARTICLES,
    //   companyName: action.companyName,
    //   publishedStartDate: action.publishedStartDate,
    //   publishedEndDate: action.publishedEndDate,
    //   titleSentiment: action.titleSentiment,
    //   categories: action.categories,
    // });
  } catch (e) {
    yield put({ type: "FETCH_FAILED", message: e.message });
  }
}
function* handlePDFGeneration(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.DOWNLOAD_COMPANY_REPORT,
      })
    );
    yield call(api.getCompanyReport, action.payload);
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.DOWNLOAD_COMPANY_REPORT,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COMAPNY_ACTION_NAME,
        requestName: actionTypes.DOWNLOAD_COMPANY_REPORT,
        error: error,
      })
    );
  }
}
function* companyRiskSaga() {
  yield takeLatest(actionTypes.GET_COMPANY_INFO, handleCompanySearch);
  yield takeLatest(actionTypes.GET_COMPANY_DETAIL, handleGetCompanyDetail);
  yield takeLatest(actionTypes.GET_COMPANY_NEWS, handleGetCompanyNews);
  yield takeLatest(actionTypes.GET_NGO_REPORT_DATA, handleNGOReportData);
  yield takeLatest(
    actionTypes.GET_COMPANY_AUTOCOMPLETE,
    handleAutoCompleteData
  );
  yield takeLatest(actionTypes.DOWNLOAD_COMPANY_REPORT, handlePDFGeneration);
}

export default companyRiskSaga;
