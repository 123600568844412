import PropTypes from "prop-types";

import { Typography, Grid, Stack, Divider } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

// utils
import { calcTierColor } from "../../worldMap/legendUtils";

// ui components
import CustomCard from "ui-component/card";
import CustomInformationIcon from "ui-component/informationIcon";
import { useSelector } from "react-redux";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";

import {
  BASE_COUNTRY_ACTION_NAME,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";

const CountryBasedIndicators = (props) => {
  const { indicatorData } = props;
  const theme = useTheme();
  const title = "Country Based Indicators";

  const dataFetchLoading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );
  return (
    <CustomCard
      titleText={title}
      hideTitleInfo={true}
      loading={dataFetchLoading}
      noData={indicatorData?.country_based_indicators.length === 0}
      bodySx={{ overflowY: "scroll" }}
    >
      {indicatorData?.country_based_indicators.map((item) => {
        return (
          <div key={item.id}>
            <Divider sx={{ width: "100%", my: 1.45 }} />
            <Grid container direction="row" alignItems="center">
              <Grid item xs={5}>
                <Stack direction="column">
                  <Stack direction="row">
                    <Typography
                      variant="h5"
                      sx={{
                        color: theme.palette.text.heading,
                        fontWeight: "600",
                        pr: 1,
                      }}
                    >
                      {item.display_name}
                    </Typography>
                    <CustomInformationIcon infoText={item.explaination_en} />
                  </Stack>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 200,
                    }}
                  >
                    {item.source}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    // fontSize: "1.125rem",
                    color:
                      item.actual_score.toString() === "Not Available"
                        ? theme.palette.grey[500]
                        : theme.palette.text.heading,
                  }}
                >
                  {item.actual_score.toString()}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    // fontSize: "1.125rem",
                    color: calcTierColor(item.risk_level),
                  }}
                >
                  {item.risk_level}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </CustomCard>
  );
};

export default CountryBasedIndicators;

CountryBasedIndicators.propTypes = {
  indicatorData: PropTypes.exact({
    population_based_indicators: PropTypes.array,
    country_based_indicators: PropTypes.array,
  }),
};
