/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import {
  GET_COUNTRY_STATISTICS,
  GET_COUNTRY_LIST,
  SET_COUNTRY_ISO_A3,
  CLEAR_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";

//assets
import SettingsAccessibilityOutlinedIcon from "@mui/icons-material/SettingsAccessibilityOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";

// ui components
import { CountryOverviewTab } from "./overviewTab";
import { CountryDetailFreedomTab } from "./freedomTab";
import { CountryDetailCorruptionTab } from "./corruptionTab";
import { CountryDetailSDGRecomendationTab } from "./SDGRecomendationTab";
import { CountryDetailNGOActionsTab } from "./NGOActionsTab";
import { ChangeCountry } from "./changeCountry";

export const CountryDetail = () => {
  const { t } = useTranslation();
  const [tabIdx, setTabIdx] = React.useState(0);
  const dispatch = useDispatch();
  const { countryCode: countryAlpha3Code } = useParams();
  const { countryList, selectedCountry } = useSelector(
    (state) => state.countryRisk
  );

  const handleTabChange = (event, newValue) => {
    setTabIdx(newValue);
  };
  useEffect(() => {
    if (countryAlpha3Code) {
      dispatch({ type: CLEAR_COUNTRY_STATISTICS });
      dispatch({ type: GET_COUNTRY_STATISTICS, payload: countryAlpha3Code });
      if (countryList.length === 0) dispatch({ type: GET_COUNTRY_LIST });
      if (selectedCountry === "") {
        dispatch({ type: SET_COUNTRY_ISO_A3, payload: countryAlpha3Code });
      }
    }

    // return () => {
    //   dispatch({ type: CLEAR_COUNTRY_STATISTICS });
    // };
  }, [dispatch, countryAlpha3Code]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ChangeCountry
        countryList={countryList}
        countryAlpha3Code={countryAlpha3Code}
        // showPDFView={selectedCountry ? true : false}
      />

      <Box
        sx={{
          position: "fixed",
          width: "100%",
          zIndex: 1,
          top: "49px", // header height
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          borderBottomLeftRadius: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Tabs
          value={tabIdx}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          selectionFollowsFocus={true}
        >
          <Tab
            icon={<WysiwygOutlinedIcon />}
            iconPosition="start"
            label={t("overviewTabNameI18n")}
          />
          <Tab
            icon={<SettingsAccessibilityOutlinedIcon />}
            iconPosition="start"
            label={t("freedomTabNameI18n")}
          />
          <Tab
            icon={<AttachMoneyOutlinedIcon />}
            iconPosition="start"
            label={t("corruptionTabNameI18n")}
          />
          <Tab
            icon={<LegendToggleOutlinedIcon />}
            iconPosition="start"
            label={t("SDGRecommendationTabNameI18n")}
          />
          {/* <Tab
            icon={<NotInterestedOutlinedIcon />}
            iconPosition="start"
            label={t("NGOActionsTabNameI18n")}
          /> */}
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: "72px", // tabs height
        }}
      >
        {tabIdx === 0 && (
          <Box sx={{ height: "100%", width: "100%" }}>
            <CountryOverviewTab />
          </Box>
        )}{" "}
        {tabIdx === 1 && (
          <Box>
            <CountryDetailFreedomTab />
          </Box>
        )}{" "}
        {tabIdx === 2 && (
          <Box>
            <CountryDetailCorruptionTab />
          </Box>
        )}{" "}
        {tabIdx === 3 && (
          <Box>
            <CountryDetailSDGRecomendationTab />
          </Box>
        )}{" "}
        {/* {tabIdx === 4 && (
          <Box>
            <CountryDetailNGOActionsTab />
          </Box>
        )} */}
      </Box>
    </Box>
  );
};
