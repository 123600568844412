/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Typography, Box, Grid, Stack, Divider } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

// ui components
import { HtmlTooltip } from "ui-component/HtmlToolTip";

// utils
import { calcTierColor } from "../../worldMap/legendUtils";

// assets
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { CountryPopulationGrowth } from "../charts/populationGrowth";
import { numFormatter, objectIsEmpty } from "utils/helper";
import { CountryGDP } from "../charts/gdpPerCapita";
import { CountryCO2Emission } from "../charts/co2Emission";
import { CountryChildrenEmployment } from "../charts/childrenEmployent";
import { CountryAirPollution } from "../charts/airPollution";
import { useEffect, useState } from "react";
import CustomCard from "ui-component/card";
import CustomInformationIcon from "ui-component/informationIcon";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";
import {
  BASE_COUNTRY_ACTION_NAME,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";

const CountryStatistics = () => {
  const { language } = useSelector((state) => state.customization);
  const {
    population,
    gdp,
    co2_emission: co2Emission,
    air_pollution: airPollution,
    child_employment: childEmployment,
  } = useSelector((state) => state.countryRisk.countryStatistics);
  const dataFetchLoading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );

  const { t } = useTranslation();
  const title = "Country Statistics";

  const theme = useTheme();
  const [dataList, setDataList] = useState([]);
  const [emptyDataList, setEmptyDataList] = useState(false);
  const calculateGDPValue = (gdp, language = "en") => {
    let nonNullGDP = gdp.filter((x) => x).pop();
    if (!nonNullGDP) {
      return "Not Available";
    }
    return `${numFormatter(nonNullGDP, language)}$`;
  };
  useEffect(() => {
    let tempList = [];
    if (population && !objectIsEmpty(population)) {
      tempList.push({
        name: "populationChartTitleI18n",
        id: 1,
        component: <CountryPopulationGrowth />,
        explaination_en: "populationInfoTextI18n",
        value: `${numFormatter(
          Object.values(population)[Object.values(population).length - 1],
          language
        )}`,
      });
    }
    if (gdp && !objectIsEmpty(gdp)) {
      tempList.push({
        name: "GDPChartTitleI18n",
        id: 2,
        component: <CountryGDP />,
        explaination_en: "GDPInfoTextI18n",
        value: calculateGDPValue(Object.values(gdp)),
      });
    }
    if (co2Emission && !objectIsEmpty(co2Emission)) {
      tempList.push({
        name: "co2EmissionChartTitleI18n",
        id: 3,
        component: <CountryCO2Emission />,
        explaination_en: "co2EmissionInfoTextI18n",
        value: `${
          Object.values(co2Emission)[Object.values(co2Emission).length - 1]
        } kt`,
      });
    }
    if (childEmployment && !objectIsEmpty(childEmployment)) {
      tempList.push({
        name: "childrenEmploymentChartTitleI18n",
        id: 4,
        component: <CountryChildrenEmployment />,
        explaination_en: "childrenEmploymentInfoTextI18n",
        value: `${
          Object.values(childEmployment)[
            Object.values(childEmployment).length - 1
          ]
        } %`,
      });
    }
    if (airPollution && !objectIsEmpty(airPollution)) {
      tempList.push({
        name: "airPollutionChartTitleI18n",
        id: 5,
        component: <CountryAirPollution />,
        explaination_en: "airPollutionInfoTextI18n",
        value: `${Object.values(airPollution)[
          Object.values(airPollution).length - 1
        ].toFixed(2)} %`,
      });
    }
    setDataList([...tempList]);
    if (
      tempList.length === 0 &&
      (population || gdp || co2Emission || childEmployment || airPollution)
    ) {
      setEmptyDataList(true);
    }
  }, [population, gdp, co2Emission, airPollution, childEmployment, language]);
  return (
    <CustomCard
      titleText={title}
      hideTitleInfo={true}
      loading={dataFetchLoading === true}
      noData={emptyDataList === true}
    >
      {dataList.map((item, idx) => {
        return (
          <Box key={idx} sx={{ height: "130px" }}>
            <Divider sx={{ width: "100%", my: 1 }} />
            <Stack direction="row" spacing={1} sx={{ height: "30px" }}>
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: "500",
                }}
              >
                {t(item.name)}
              </Typography>
              <CustomInformationIcon infoText={t(item.explaination_en)} />
            </Stack>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: "70px" }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                {item.component}
              </Grid>
              <Grid
                item
                xs
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography variant="h3" sx={{ fontWeight: 600 }}>
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </CustomCard>
  );
};

export default CountryStatistics;
