/* eslint-disable no-unused-vars */
export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 500,
    //       borderRadius: "4px",
    //     },
    //   },
    // },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          background: theme.colors.grey200,
          width: "100%",
          height: "48px",
          minHeight: "48x",
        },
        indicator: {
          backgroundColor: theme.colors.secondaryDark,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: "700",
          height: "48px",
          minHeight: "48px",
          color: theme.colors.grey500,
          "&:hover": {
            color: theme.colors.grey900,
            background: theme.colors.secondaryLight,
          },
          "&.Mui-selected": {
            background: theme.colors.secondary200,
            color: theme.colors.grey900,
          },
        },
      },
    },

    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       color: theme.darkTextPrimary,
    //       paddingTop: "10px",
    //       paddingBottom: "10px",
    //       "&.Mui-selected": {
    //         color: theme.menuSelected,
    //         backgroundColor: theme.menuSelectedBack,
    //         "&:hover": {
    //           backgroundColor: theme.menuSelectedBack,
    //         },
    //         "& .MuiListItemIcon-root": {
    //           color: theme.menuSelected,
    //         },
    //       },
    //       "&:hover": {
    //         backgroundColor: theme.menuSelectedBack,
    //         color: theme.menuSelected,
    //         "& .MuiListItemIcon-root": {
    //           color: theme.menuSelected,
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiListItemIcon: {
    //   styleOverrides: {
    //     root: {
    //       color: theme.darkTextPrimary,
    //       minWidth: "36px",
    //     },
    //   },
    // },
    // MuiListItemText: {
    //   styleOverrides: {
    //     primary: {
    //       color: theme.textDark,
    //     },
    //   },
    // },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       color: theme.textDark,
    //       "&::placeholder": {
    //         color: theme.darkTextSecondary,
    //         fontSize: "0.875rem",
    //       },
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "100% !important",
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          // borderRadius: "1px",
          "& .MuiOutlinedInput-notchedoutline": {
            borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedoutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 700,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedoutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    // MuiAccordion: {
    //   styleOverrides: {
    //     root: {
    // backgroundColor: theme?.colors?.grey100,
    // "&.MuiPaper-root": {
    // elevation: 1,
    // },
    //     },
    //   },
    // },
    // MuiAccordionSummary: {
    //   styleOverrides: {
    //     root: {
    //       borderTopLeftRadius: "4px",
    //       borderTopRightRadius: "4px",
    //       "&.Mui-expanded": {
    //         minHeight: "40px",
    //       },
    //     },
    //     content: {
    //       "&.Mui-expanded": {
    //         margin: "10px 0",
    //       },
    //     },
    //   },
    // },
    // MuiSlider: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-disabled": {
    //         color: theme.colors?.grey300,
    //       },
    //     },
    //     mark: {
    //       backgroundColor: theme.paper,
    //       width: "4px",
    //     },
    //     valueLabel: {
    //       color: theme?.colors?.primaryLight,
    //     },
    //   },
    // },
    // MuiDivider: {
    //   styleOverrides: {
    //     root: {
    //       borderColor: theme.divider,
    //       opacity: 1,
    //     },
    //   },
    // },
    // // MuiAvatar: {
    // //   styleOverrides: {
    // //     root: {
    // //       color: theme.colors?.primaryDark,
    // //       background: theme.colors?.primary200,
    // //     },
    // //   },
    // // },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: theme.paper,
    //       background: theme.colors?.grey700,
    //     },
    //   },
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       ".MuiInputLabel-root": {
    //         fontSize: "1rem",
    //       },
    //     },
    //   },
    // },
  };
}
