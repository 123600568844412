/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";

// mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import {
  calculateChartMultilineName,
  capitalizeAndRemoveUnderscore,
} from "utils/helper";

// ui-components
import CustomCard from "ui-component/card";

const CalTooltip = (props, total) => {
  const { label, value } = props.datum.data;
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 2,
        width: "auto",
        height: "auto",
        background: "#ffffff",
        border: "1px solid" + theme.palette.grey[700],
        borderRadius: "4px",
      }}
    >
      <Typography
        sx={{ color: theme.palette.grey[600], fontWeight: 700 }}
      >{`${label}: ${((value / total) * 100).toFixed(1)}%`}</Typography>
    </Box>
  );
};
// CalTooltip.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.any,
// };

export const CountryRiskSentiment = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const { riskSentiment } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  let colorpalette = ["#003f5c", "#ffa600", "#bc5090"];
  let chartTitle = t("sentimentChartTitleI18n");
  // eslint-disable-next-line no-unused-vars
  const CenteredMetric = ({ _, centerX, centerY }) => {
    let max = 0;
    let maxSentiment = "";
    Object.keys(riskSentiment.sentiment).forEach((item) => {
      if (riskSentiment.sentiment[item] > max) {
        max = riskSentiment.sentiment[item];
        maxSentiment = item;
      }
    });
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "30px",
            fontWeight: 600,
            fill: theme.palette.primary[800],
          }}
        >
          {capitalizeAndRemoveUnderscore(maxSentiment)}
        </text>
      </>
    );
  };

  useEffect(() => {
    if (riskSentiment) {
      let tempData = [];
      let tempTotal = 0;
      Object.keys(riskSentiment.sentiment).map((key, idx) => {
        // if (riskSentiment.sentiment[key] !== 0) {
        let color =
          capitalizeAndRemoveUnderscore(key) == "High risk"
            ? colorpalette[2]
            : capitalizeAndRemoveUnderscore(key) == "Low risk"
            ? colorpalette[0]
            : colorpalette[1];
        tempTotal += riskSentiment.sentiment[key];
        tempData.push({
          id: idx,
          label: capitalizeAndRemoveUnderscore(key),
          value: riskSentiment.sentiment[key],
          color: color,
        });
        // }
      });
      setData(tempData);
      setTotal(tempTotal);
    }
  }, [riskSentiment]);
  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={t("sentimentChartInfoI18n")}
      loading={loading}
      noData={riskSentiment?.risk_score === 0}
    >
      <ResponsivePie
        data={data}
        startAngle={90}
        endAngle={450}
        margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
        innerRadius={0.7}
        padAngle={2}
        cornerRadius={2}
        activeOuterRadiusOffset={10}
        activeInnerRadiusOffset={10}
        borderWidth={1}
        // colors={colorpalette}
        colors={{ datum: "data.color" }}
        arcLabel={function (e) {
          return ((e.value / total) * 100).toFixed(1) + "%";
        }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.7]],
        }}
        tooltip={(data) => CalTooltip(data, total)}
        arcLinkLabel={(d) => {
          const label = calculateChartMultilineName([d.data.label], 10)[0];
          return (
            <>
              {label.length === 1 ? (
                <tspan x="0" dy="0">
                  {label[0]}
                </tspan>
              ) : (
                label.map((item, idx) => {
                  return (
                    <tspan key={idx} x="0" dy={idx === 0 ? "-1em" : "1.2em"}>
                      {item}
                    </tspan>
                  );
                })
              )}
            </>
          );
        }}
        arcLinkLabelsStraightLength={10}
        arcLinkLabelsDiagonalLength={10}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsTextColor={theme.palette.grey[600]}
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={theme.palette.grey[400]}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={theme.palette.grey[50]}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          // "legends",
          CenteredMetric,
        ]}
      />
    </CustomCard>
  );
};

CountryRiskSentiment.propTypes = {
  loading: PropTypes.bool,
};
