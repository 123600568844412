import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useTranslation } from "react-i18next";

import {
  BASE_COUNTRY_ACTION_NAME,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";
import WidgetCard from "ui-component/cards/WiddgetCard";
import { CountryDetailCorrpution } from "./charts/corruption";
//assets
import ScoreboardOutlinedIcon from "@mui/icons-material/ScoreboardOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import corruptionScale from "assets/images/risk/corruptionScale.png";
import { TitleTypography } from "ui-component/titleTypography";

export const CountryDetailCorruptionTab = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [latestReport, setLatestReport] = useState({});
  const { name: countryName, corruption } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const { language, sideDrawerOpened } = useSelector(
    (state) => state.customization
  );
  const loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );
  const explaination = {
    en: [
      "Corruption is the abuse of entrusted power for private gain. Corruption erodes trust, weakens democracy, hampers economic development and further exacerbates inequality, poverty, social division and the environmental crisis.",
      "Exposing corruption and holding the corrupt to account can only happen if we understand the way corruption works and the systems that enable it.",
    ],
    ja: [
      "汚職とは、私的利益のために委任された権力を乱用することです。腐敗は信頼を損ない、民主主義を弱め、経済発展を阻害し、不平等、貧困、社会的分裂、環境危機をさらに悪化させます。",
      "腐敗を暴露し、腐敗を説明することは、腐敗がどのように機能し、それを可能にするシステムを理解している場合にのみ可能です。",
    ],
  };
  useEffect(() => {
    if (corruption && corruption.length) {
      setLatestReport({ ...corruption[corruption.length - 1] });
    }
  }, [corruption]);
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        py: 2,
        px: {
          xs: 2,
          sm: 5,
          md: sideDrawerOpened ? 5 : 10,
          lg: sideDrawerOpened ? 10 : 20,
          xl: sideDrawerOpened ? 40 : 60,
        },
      }}
    >
      <Typography variant="h2" sx={{ color: theme.palette.primary[800] }}>
        {countryName
          ? t("corruptionTabTitleI18n") + countryName?.toUpperCase()
          : ""}
      </Typography>
      <Grid container sx={{ px: 4, height: "100%", width: "100%" }}>
        {/* One liner */}
        <Grid item xs={12} sx={{ mb: 2 }}>
          {explaination[language].map((item, index) => {
            return (
              <Typography
                key={index}
                variant="h5"
                sx={{
                  color: theme.palette.text.main,
                  mt: 1,
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                }}
              >
                {item}
              </Typography>
            );
          })}
        </Grid>
        {/* widgets */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <TitleTypography
            title={t("corruptionScoreI18n") + latestReport.year}
          />

          <Box sx={{ mt: 1.5, height: "100%", width: "100%" }}>
            <Grid container sx={{ width: "100%", height: "100%" }}>
              <Grid
                item
                xs={12}
                sm={4}
                lg={4}
                sx={{
                  pr: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <WidgetCard isLoading={loading} cardTheme={"primary"}>
                  <Grid
                    container
                    rowSpacing={2}
                    direction="row"
                    alignItems="start"
                    justifyContent="start"
                  >
                    <Grid item xs={12}>
                      <Typography
                        fontWeight={300}
                        variant={"h1"}
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[50],
                        }}
                      >
                        {latestReport?.score}
                        <Typography
                          fontWeight={500}
                          variant={"h2"}
                          component={"span"}
                          sx={{
                            color: theme.palette.grey[50],
                          }}
                        >
                          {" / 100"}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography
                        variant={"h3"}
                        component={"span"}
                        mr={2}
                        sx={{ color: theme.palette.grey[50] }}
                      >
                        {t("scoreI18n")}
                      </Typography>
                      <Avatar
                        variant="rounded"
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.largeAvatar,
                          backgroundColor: theme.palette.primary[800],
                        }}
                      >
                        <ScoreboardOutlinedIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                </WidgetCard>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  pr: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pl: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <WidgetCard isLoading={loading} cardTheme={"secondary"}>
                  <Grid
                    container
                    rowSpacing={2}
                    direction="row"
                    alignItems="start"
                    justifyContent="start"
                  >
                    <Grid item xs={12}>
                      <Typography
                        fontWeight={300}
                        variant={"h1"}
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[50],
                        }}
                      >
                        {latestReport?.rank}
                        <Typography
                          fontWeight={500}
                          variant={"h2"}
                          component={"span"}
                          sx={{
                            color: theme.palette.grey[50],
                          }}
                        >
                          {" / 180"}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography
                        variant={"h3"}
                        component={"span"}
                        mr={2}
                        sx={{ color: theme.palette.grey[50] }}
                      >
                        {t("rankI18n")}
                      </Typography>
                      <Avatar
                        variant="rounded"
                        sx={{
                          ...theme.typography.commonAvatar,
                          ...theme.typography.largeAvatar,
                          backgroundColor: theme.palette.secondary[800],
                        }}
                      >
                        <LeaderboardOutlinedIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                </WidgetCard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* chart */}
        <Grid item xs={12} sx={{ height: "500px", width: "100%", my: 2 }}>
          <CountryDetailCorrpution />
        </Grid>

        {/* scale explaination */}
        <Grid item xs={12} sx={{ mb: 4 }}>
          <TitleTypography title={t("scaleExplanationI18n")} />

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              height: "250px",
              width: "100%",
              // pb: 2,
              px: {
                xs: 2,
                sm: 5,
                md: sideDrawerOpened ? 0 : 7,
                lg: sideDrawerOpened ? 5 : 10,
                xl: sideDrawerOpened ? 7.5 : 14,
              },
              my: 4,
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                height: { xs: "50%", sm: "50%", md: "70%", lg: "80%" },
                pr: 1,
              }}
            >
              <img
                width="auto"
                height="100%"
                // maxHeight="250px"
                src={corruptionScale}
                alt=" 404 not found"
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ height: "100%", width: "100%", pl: 1 }}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h3"
                fontWeight={300}
                sx={{ textAlign: "center" }}
              >
                {t("scaleRangeI18n")}
                <span style={{ fontWeight: 500, fontStyle: "italic" }}>
                  0 - 100
                </span>
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  borderBottomWidth: 2,
                  width: "100%",
                  borderColor: theme.palette.grey[500],
                }}
              />
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h3" fontWeight={300}>
                  0 is{" "}
                  <span style={{ fontWeight: 500, fontStyle: "italic" }}>
                    {t("corruptionScaleCleanI18n")}
                  </span>{" "}
                  100 is{" "}
                  <span style={{ fontWeight: 500, fontStyle: "italic" }}>
                    {t("corruptionScaleCorruptI18n")}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
