import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import useTheme from "@mui/material/styles/useTheme";

import { calculateChartMultilineName } from "utils/helper";

// assets
import sdg1 from "assets/images/risk/sdg/01.png";
import sdg2 from "assets/images/risk/sdg/02.png";
import sdg3 from "assets/images/risk/sdg/03.png";
import sdg4 from "assets/images/risk/sdg/04.png";
import sdg5 from "assets/images/risk/sdg/05.png";
import sdg6 from "assets/images/risk/sdg/06.png";
import sdg7 from "assets/images/risk/sdg/07.png";
import sdg8 from "assets/images/risk/sdg/08.png";
import sdg9 from "assets/images/risk/sdg/09.png";
import sdg10 from "assets/images/risk/sdg/10.png";
import sdg11 from "assets/images/risk/sdg/11.png";
import sdg12 from "assets/images/risk/sdg/12.png";
import sdg13 from "assets/images/risk/sdg/13.png";
import sdg14 from "assets/images/risk/sdg/14.png";
import sdg15 from "assets/images/risk/sdg/15.png";
import sdg16 from "assets/images/risk/sdg/16.png";
import sdg17 from "assets/images/risk/sdg/17.png";

import CustomCard from "ui-component/card";
// import { calcTierColor } from "../../worldMap/legendUtils";

export const CountryDetailSDGGoal = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { sdgGoalTarget } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  let chartTitle = t("SDGGoalChartTitleI18n");
  let xAxisName = t("SDGGoalChartXAxisNameI18n");
  let yAxisName = t("SDGGoalChartYAxisNameI18n");
  const iconList = [
    { icon: sdg1, color: "#e5253b" },
    { icon: sdg2, color: "#dea83b" },
    { icon: sdg3, color: "#4d9f39" },
    { icon: sdg4, color: "#c51a2d" },
    { icon: sdg5, color: "#ff3921" },
    { icon: sdg6, color: "#26bde2" },
    { icon: sdg7, color: "#fcc30c" },
    { icon: sdg8, color: "#a21842" },
    { icon: sdg9, color: "#fc6925" },
    { icon: sdg10, color: "#dd1367" },
    { icon: sdg11, color: "#fe9d25" },
    { icon: sdg12, color: "#be8b2f" },
    { icon: sdg13, color: "#3f7e44" },
    { icon: sdg14, color: "#0e97d9" },
    { icon: sdg15, color: "#56c12a" },
    { icon: sdg16, color: "#00679d" },
    { icon: sdg17, color: "#18486a" },
  ];
  const calcTierColor = () => {
    let tempColorList = [];
    sdgGoalTarget.goal.map((data) => {
      let idx = parseInt(data.name.split(".")[0]);
      tempColorList.push(iconList[idx - 1]["color"]);
    });
    return tempColorList;
  };
  useEffect(() => {
    if (sdgGoalTarget?.goal) {
      setSeries([
        {
          name: yAxisName,
          data: sdgGoalTarget.goal.map((data) => data.count),
        },
      ]);
      setOption({
        chart: {
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        colors: [...calcTierColor()],

        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: "top",
              hideOverflowingLabels: true,
              orientation: "horizontal",
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div 
                      style="height:150px; width:auto; display:flex;
                            flex-direction:column; align-items:center; justify-content:center; margin:4px; "
                      >
                      <div>
                        <img width="auto" height="120px" src=${
                          filterIcon(
                            w?.globals.categoryLabels[dataPointIndex][0]
                          )["icon"]
                        } alt="not found"/>
                      </div>
                      <div>
                        Count : 
                        <b>${series[seriesIndex][dataPointIndex]}</b>
                      </div>
                    </div>`;
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [theme.palette.grey[900]],
          },
          textAnchor: "middle",
          offsetY: -20,
          background: {
            enabled: false,
          },
        },
        yaxis: [
          {
            title: {
              text: yAxisName,
            },
            forceNiceScale: true,
            show: true,
          },
        ],
        xaxis: {
          type: "category",
          tickPlacement: "off",
          title: {
            text: xAxisName,
          },
          categories: calculateChartMultilineName(
            sdgGoalTarget.goal.map((data) => data.name),
            25
          ),
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "11px",
            },
          },
        },
      });
    }
  }, [sdgGoalTarget?.goal, xAxisName, yAxisName]);

  const filterIcon = (key) => {
    let idx = key.split(".")[0];

    return iconList[idx - 1];
  };
  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={t("SDGGoalChartInfoI18n")}
      loading={loading}
      noData={sdgGoalTarget.goal?.length === 0}
    >
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="bar"
          height="100%"
          width="100%"
        />
      )}
    </CustomCard>
  );
};
CountryDetailSDGGoal.propTypes = {
  loading: PropTypes.bool,
};
