import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./action";
import * as api from "./api";
import * as helperActionCreators from "redux/loadingAndError/actionCreators";

function* handleGetCountriesComparisionData(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_COUNTRIES_STATISTICS,
      })
    );
    const countriesComparisionData = yield call(
      api.getCountriesComparisionData,
      action.payload
    );
    yield put({
      type: actionTypes.SET_COUNTRIES_STATISTICS,
      payload: countriesComparisionData,
    });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_COUNTRIES_STATISTICS,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_COUNTRIES_STATISTICS,
        error: error,
      })
    );
  }
}

function* handleGetFilteredNGORecords(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_FILTERED_NGO_RECORDS,
      })
    );
    const filteredNGORecords = yield call(
      api.getFilteredNGORecords,
      action.payload.country,
      action.payload.indicatorClass,
      action.payload.indicatorKey,
      action.payload.startDate,
      action.payload.endDate
    );
    yield put({
      type: actionTypes.SET_FILTERED_NGO_RECORDS,
      payload: filteredNGORecords,
    });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_FILTERED_NGO_RECORDS,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COMPARE_COUNTRIES,
        requestName: actionTypes.SET_FILTERED_NGO_RECORDS,
        error: error,
      })
    );
  }
}

function* countriesComparisionSaga() {
  yield takeLatest(
    actionTypes.GET_COUNTRIES_STATISTICS,
    handleGetCountriesComparisionData
  );
  yield takeLatest(
    actionTypes.GET_FILTERED_NGO_RECORDS,
    handleGetFilteredNGORecords
  );
}

export default countriesComparisionSaga;
