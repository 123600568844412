/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";

import { HtmlTooltip } from "ui-component/HtmlToolTip";

// assets
// import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
// import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFormik } from "formik";
// import PropTypes from "prop-types";
import { object, string } from "yup";
import {
  BASE_USER_ACTION_NAME,
  UPDATE_USER_INFORMATION,
} from "redux/authentication/action";
import { useDispatch, useSelector } from "react-redux";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";
import { LoadingButton } from "@mui/lab";
import { getBrowserStorageAccessToken } from "utils/authentication";

const UserInformation = (props) => {
  const { userInfo } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const updatingUserInformation = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_USER_ACTION_NAME,
      requestName: UPDATE_USER_INFORMATION,
    })
  );
  const validationSchema = object({
    phoneNumber: string("Enter phone number").required(
      "Phone Number is required"
    ),
    firstName: string("Enter your first name").required(
      "First Name is required"
    ),
    lastName: string("Enter your last name").required("Last Name is required"),
    companyName: string("Enter your company name").required(
      "Comany Name is required"
    ),
  });
  const formik = useFormik({
    initialValues: {
      phoneNumber: userInfo?.phoneNumber,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      companyName: userInfo?.companyName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: UPDATE_USER_INFORMATION,
        payload: values,
        token: getBrowserStorageAccessToken(),
      });
    },
  });

  return (
    <Container
      maxWidth={"lg"}
      sx={{
        height: "100%",
        mt: 5,
        p: 5,
        border: "1px solid" + theme.palette.grey[300],
        borderRadius: "4px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontFamily="monospace"
            sx={{ color: theme.palette.primary.main }}
          >
            Basic Information
          </Typography>
          <Grid
            container
            sx={{ width: "100%", height: "100%", mt: 3 }}
            alignItems="center"
            justifyContent="center"
            rowSpacing={2}
          >
            <Grid item xs={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Typography> {"Email / Username"} </Typography>
                <HtmlTooltip title="Cannot edit username">
                  <IconButton aria-label="info-button">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </HtmlTooltip>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={3}
                sx={{ width: "100%", height: "100%" }}
              >
                <Typography id="email" name="email" variant="h4">
                  {userInfo?.email}
                </Typography>

                {/* {emailVerified === false ? (
                <HtmlTooltip title="Email is not verified , click to send verification Email">
                  <Button
                    color={"error"}
                    variant="outlined"
                    size="small"
                    startIcon={<ReportOutlinedIcon />}
                  >
                    <Typography>{"verify"}</Typography>
                  </Button>
                </HtmlTooltip>
              ) : (
                <HtmlTooltip title="Email is verified">
                  <IconButton aria-label="email-verified">
                    <VerifiedOutlinedIcon color={"success"} />
                  </IconButton>
                </HtmlTooltip>
              )} */}
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Typography> {"First Name"} </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="firstName"
                name="firstName"
                size="small"
                fullWidth
                {...formik.getFieldProps("firstName")}
                error={
                  formik.errors.firstName &&
                  formik.errors.firstName?.length !== 0
                }
                helperText={formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography> {"Last Name"} </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="lastName"
                name="lastName"
                size="small"
                fullWidth
                {...formik.getFieldProps("lastName")}
                error={
                  formik.errors.lastName && formik.errors.lastName?.length !== 0
                }
                helperText={formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography> {"Company"} </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="companyName"
                name="companyName"
                size="small"
                fullWidth
                {...formik.getFieldProps("companyName")}
                error={
                  formik.errors.companyName &&
                  formik.errors.companyName?.length !== 0
                }
                helperText={formik.errors.companyName}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography> {"Phone Number"} </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                size="small"
                fullWidth
                {...formik.getFieldProps("phoneNumber")}
                error={
                  formik.errors.phoneNumber &&
                  formik.errors.phoneNumber?.length !== 0
                }
                helperText={formik.errors.phoneNumber}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={3}
        >
          <LoadingButton
            loading={updatingUserInformation}
            loadingPosition="start"
            startIcon={<SendOutlinedIcon size={"small"} />}
            variant="contained"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {/* <Button
            variant="contained"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          > */}
            {`Update`}
            {/* </Button> */}
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
};

export default UserInformation;
