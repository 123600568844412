import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// mui
import { Typography, Stack, Divider } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

// ui components
import CustomCard from "ui-component/card";
import CustomInformationIcon from "ui-component/informationIcon";

// loading
import { namedRequestsInProgress } from "redux/loadingAndError/selector";
import {
  BASE_COUNTRY_ACTION_NAME,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";

const PopulationBasedIndicators = (props) => {
  const { indicatorData } = props;
  const theme = useTheme();
  const title = "Population Based Indicators";

  const dataFetchLoading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );

  return (
    <CustomCard
      titleText={title}
      hideTitleInfo={true}
      loading={dataFetchLoading}
      noData={indicatorData?.population_based_indicators.length === 0}
    >
      {indicatorData?.population_based_indicators.map((item) => {
        return (
          <div key={item.id}>
            <Divider sx={{ width: "100%", my: 1.45 }} />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="column">
                <Stack direction="row">
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.text.heading,
                      fontWeight: "600",
                      pr: 1,
                    }}
                  >
                    {item.display_name}
                  </Typography>
                  <CustomInformationIcon
                    infoText={item.indicator_explaination}
                  />
                </Stack>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 200,
                  }}
                >
                  {item.source}
                </Typography>
              </Stack>
              <Typography
                variant="h4"
                sx={{
                  color:
                    item.score.toString() === "Not Available"
                      ? theme.palette.grey[500]
                      : theme.palette.text.heading,
                }}
              >
                {item.score.toString()}
              </Typography>
            </Stack>
          </div>
        );
      })}
    </CustomCard>
  );
};

export default PopulationBasedIndicators;

PopulationBasedIndicators.propTypes = {
  indicatorData: PropTypes.exact({
    population_based_indicators: PropTypes.array,
    country_based_indicators: PropTypes.array,
  }),
};
