/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import NavigationScroll from "./utils/NavigationScroll";
import { SnackbarNotification } from "ui-component/snackbar";
import themes from "./themes";
import Routes from "./routes";

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { message, severity } = useSelector(
    (state) => state.requestHelper.notification
  );
  useEffect(() => {
    i18n.changeLanguage(customization.language);
  }, [customization.language]);

  return (
    <StyledEngineProvider injectFirst>
      {/* changing the css priority for overiding */}
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline /> {/* normalization of css */}
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
        <SnackbarNotification
          key={message + severity}
          message={message}
          severity={severity}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
