import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux";
import App from "./App";

// style + assets
import "./assets/scss/style.scss";
import config from "./config";
import "./i18n";
import { AxiosPrivateInterceptorSetup } from "common/axiosPrivate";

// sentry logging service only enabled in production
process.env.REACT_APP_ENV === "production" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    debug: false,
    release: "v1.0",
    environment: "production",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
  });
process.env.REACT_APP_ENV === "staging" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    debug: false,
    release: "v1.0",
    environment: "stagging",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
  });

function AxiosInterceptorNavigate() {
  let navigate = useNavigate();
  useEffect(() => {
    AxiosPrivateInterceptorSetup(navigate);
  }, [navigate]);
  return <></>;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <HelmetProvider>
      {/* used to modify the HTML meta data like page name and links */}
      <Provider store={store}>
        <BrowserRouter basename={config.basename}>
          <AxiosInterceptorNavigate />
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
