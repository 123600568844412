/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import Chart from "react-apexcharts";
// mui
import useTheme from "@mui/material/styles/useTheme";
// ui-components
import CustomCard from "ui-component/card";
// utils
import { calculateChartMultilineName } from "utils/helper";
import { Box } from "@mui/material";

export const MostTargetedCompaniesByNGO = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  let xAxisName = t("SDGTargetChartXAxisNameI18n");
  let yAxisName = t("SDGTargetChartYAxisNameI18n");
  const [data, setData] = useState([]);
  const { targetedCompanies } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  let [totalValue, setTotalValue] = useState(0);
  let chartTitle = "Most targeted companies by NGO's";
  useEffect(() => {
    if (targetedCompanies?.length > 0) {
      setSeries([
        {
          name: yAxisName,
          data: targetedCompanies.map((item) => item.count),
        },
      ]);
      setOption({
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: [
          // "#ffa600",
          // "#ff7c43",
          // "#f95d6a",
          // "#d45087",
          // "#a05195",
          // "#665191",
          // "#2f4b7c",
          // "#003f5c",
          theme.palette.chart[900],
          theme.palette.chart[800],
          theme.palette.chart[700],
          theme.palette.chart[600],
          theme.palette.chart[500],
          theme.palette.chart[400],
          theme.palette.chart[300],
          theme.palette.chart[200],
          theme.palette.chart[100],
          theme.palette.chart[50],
        ],

        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            distributed: true,
            barHeight: "60%",
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [theme.palette.grey[900]],
          },
          textAnchor: "end",
          offsetX: 40,
          background: {
            enabled: false,
          },
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: "topRight",
          },
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 120,
            maxWidth: 300,
            style: {
              fontSize: "10px",
              fontWeight: 600,
            },
          },
          title: {
            text: yAxisName,
            style: {
              fontSize: "12px",
              fontWeight: 800,
            },
          },
          forceNiceScale: false,
          show: true,
        },

        xaxis: {
          title: {
            text: xAxisName,
            style: {
              fontSize: "10px",
              fontWeight: 800,
            },
          },
          tickAmount: 4,
          tickPlacement: "on",
          // max: calcualteMaxForXAxis(),
          // categories: sdgGoalTarget.target[goalCategory].map(
          //   (data) => data.name
          // ),
          categories: calculateChartMultilineName(
            targetedCompanies.map((item) => item.company),
            50
          ),
          labels: {
            show: true,
            style: {
              fontSize: "10px",
              fontWeight: 600,
            },
          },
        },
      });
    }
  }, [targetedCompanies, xAxisName, yAxisName]);
  // useEffect(() => {
  //   if (targetedCompanies) {
  //     const tempList = [];
  //     let tempTotal = 0;
  //     targetedCompanies.map((item, idx) => {
  //       tempTotal += item.count;
  //       tempList.push({
  //         id: item.company,
  //         label: item.company,
  //         value: item.count,
  //         color: colorpalette[idx % 11],
  //       });
  //     });
  //     setData(tempList);
  //     setTotalValue(tempTotal);
  //   }
  // }, [targetedCompanies]);

  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={chartTitle}
      loading={loading}
      noData={targetedCompanies?.length === 0}
    >
      {option && series.length !== 0 && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Chart
            options={option}
            series={series}
            type="bar"
            height="100%"
            width="100%"
          />
        </Box>
      )}

      {/* {data.length !== 0 && (
        <ResponsivePie
          data={data}
          startAngle={90}
          endAngle={450}
          margin={{ top: 40, right: 140, bottom: 20, left: 140 }}
          innerRadius={0}
          padAngle={0}
          cornerRadius={0}
          activeOuterRadiusOffset={10}
          activeInnerRadiusOffset={10}
          borderWidth={1}
          // colors={{ scheme: "red_blue" }}
          colors={{ datum: "data.color" }}
          // enableArcLabels={false}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.5]],
          }}
          arcLabel={function (e) {
            return ((e.value / totalValue) * 100).toFixed(0) + "%";
          }}
          arcLinkLabel={(d) => {
            const label = calculateChartMultilineName([d.data.label], 20)[0];
            return (
              <>
                {label.length === 1 ? (
                  <tspan x="0" dy="0">
                    {label[0]}
                  </tspan>
                ) : (
                  label.map((item, idx) => {
                    return (
                      <tspan key={idx} x="0" dy={idx === 0 ? "-1em" : "1.2em"}>
                        {item}
                      </tspan>
                    );
                  })
                )}
              </>
            );
          }}
          arcLinkLabelsStraightLength={10}
          arcLinkLabelsDiagonalLength={20}
          arcLinkLabelsSkipAngle={5}
          arcLinkLabelsTextColor={theme.palette.grey[600]}
          arcLinkLabelsThickness={1}
          arcLinkLabelsColor={theme.palette.grey[400]}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={theme.palette.grey[50]}
          layers={["arcs", "arcLabels", "arcLinkLabels"]}
        />
      )} */}
    </CustomCard>
  );
};
MostTargetedCompaniesByNGO.propTypes = {
  loading: PropTypes.bool,
};
