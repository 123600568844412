import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./action";
import * as api from "./api";
import * as helperActionCreators from "redux/loadingAndError/actionCreators";

function* handleWorldMapGeoJsonData() {
  try {
    const worldMapResponse = yield call(api.getWorldMapJson);
    const countryRiskCategoryListResponse = yield call(
      api.getCountryRiskCategoryList
    );
    yield put({
      type: actionTypes.SET_WORLD_MAP_JSON_DATA,
      worldMapData: worldMapResponse,
      countryRiskCategoryListData: countryRiskCategoryListResponse,
    });
    // yield put({ type: actionTypes.SET_WORLD_MAP_JSON_DATA, payload: worldMapResponse });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}
function* handleCountryList() {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: actionTypes.SET_COUNTRY_LIST,
      })
    );
    const countryList = yield call(api.getCountryList);
    yield put({ type: actionTypes.SET_COUNTRY_LIST, payload: countryList });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: actionTypes.SET_COUNTRY_LIST,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: actionTypes.SET_COUNTRY_LIST,
        error: error,
      })
    );
  }
}

function* handleCountyStatisticsData(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: actionTypes.SET_COUNTRY_STATISTICS,
      })
    );
    const countryStatistics = yield call(
      api.getCountryStatistics,
      action.payload
    );
    const freedomAssociationAverage = yield call(
      api.getFreedomAssociationAverage
    );
    const corruptionAssociationAverage = yield call(
      api.getCorruptionAssociationAverage
    );
    const indicatorData = yield call(api.getIndicatorData, action.payload);
    const sdgGoalTarget = yield call(api.getSDGGoalTarget, action.payload);
    // const ngoIssue = yield call(api.getNGOTopActivistAction, action.payload);
    // const ngoActionCount = yield call(api.getNGOActionCount, action.payload);
    const targetedCompanies = yield call(
      api.getMostTargetCompaniesByNGOs,
      action.payload
    );
    const riskSentiment = yield call(
      api.getCountryRiskSentiment,
      action.payload
    );
    const response = {
      ...countryStatistics,
      freedomAssociationAverage,
      corruptionAssociationAverage,
      sdgGoalTarget,
      // ngoIssue,
      // ngoActionCount,
      riskSentiment,
      indicatorData,
      targetedCompanies,
    };
    yield put({ type: actionTypes.SET_COUNTRY_STATISTICS, payload: response });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: actionTypes.SET_COUNTRY_STATISTICS,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_COUNTRY_ACTION_NAME,
        requestName: action.SET_COUNTRY_STATISTICS,
        error: error,
      })
    );
  }
}

function* countryRiskSaga() {
  yield takeLatest(
    actionTypes.GET_WORLD_MAP_JSON_DATA,
    handleWorldMapGeoJsonData
  );
  yield takeLatest(
    actionTypes.GET_COUNTRY_STATISTICS,
    handleCountyStatisticsData
  );
  yield takeLatest(actionTypes.GET_COUNTRY_LIST, handleCountryList);
}

export default countryRiskSaga;
