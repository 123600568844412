/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";

import { HtmlTooltip } from "ui-component/HtmlToolTip";

//assets
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

export const CountryAirPollution = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const { air_pollution: airPollution } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );

  let chartTitle = t("airPollutionChartTitleI18n");
  let yAxisName = t("airPollutionYAxisNameI18n");
  let xAxisName = t("yearI18n");
  let safetyLimit = t("airPollutionSafetyLimitI18n");
  let infoText = t("airPollutionInfoTextI18n");

  useEffect(() => {
    if (airPollution) {
      let seriesData = Object.values(airPollution);
      setSeries([
        {
          name: yAxisName,
          data: Object.values(airPollution),
        },
      ]),
        setOption({
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          // title: {
          //   text: `PM2.5 =
          //     ${seriesData[seriesData.length - 1]?.toFixed(2)} μg/m³
          //   `,
          //   // align: "start",
          //   style: {
          //     color: theme.palette.text.primary,
          //     // fontSize: "20px",
          //   },
          // },
          dataLabels: {
            enabled: false,
          },

          colors: [theme.palette.chart[800]],
          stroke: {
            width: 3,
            curve: "smooth",
          },

          xaxis: {
            // title: {
            //   text: xAxisName,
            // },
            categories: Object.keys(airPollution),
            tickPlacement: "on",
          },
          yaxis: {
            // min: 0,
            // max: 100,
            // decimalsInFloat: 2,
            // tickAmount: 10,
            forceNiceScale: true,
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              },
            },
            // title: {
            //   text: yAxisName + " (μg/m³)",
            // },
          },
        });
    }
  }, [airPollution, yAxisName, xAxisName, safetyLimit]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="area"
          height="100%"
          width="100%"
        />
      )}
    </Box>
  );
};
