/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { object, string, date, ref, array } from "yup";

import {
  CLEAR_COUNTRIES_STATISCS,
  GET_COUNTRIES_STATISTICS,
  SET_SELECTED_COUNTRIES,
} from "redux/compareCountries/action";

// mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
  Typography,
  useTheme,
  Grid,
  Button,
  Stack,
  Box,
  Chip,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  FormControl,
} from "@mui/material";

import { HtmlTooltip } from "ui-component/HtmlToolTip";
import moment from "moment";

// assets
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
// import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import BackspaceIcon from "@mui/icons-material/Backspace";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik } from "formik";
import { sectorNameList } from "utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const validationSchema = object({
  selectedCountries: array().of(object()).min(2, "Select atleast 2 Country"),
  dateFilter: string().required("date filter is required"),
  category: array()
    .of(string().required("category is required"))
    .min(1, "Select atleast one category"),
  sector: array()
    .of(string().required("category is required"))
    .min(1, "Select atleast one sector"),
  startDate: date().required("Start Date is required"),
  endDate: date()
    .required("End Date is required")
    .min(ref("startDate"), "End date cannot be earlier than start date"),
});
const SearchMultipleCountries = (props) => {
  const { countryList, searchAccordionState, setSearchAccordionState } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const customTypeDefaultDifference = 365 * 6; // in days
  const defaultAllDate = new Date("2012-10-01T00:00:00.000Z");
  // text
  const searchCountryLabel = "Search Countries";
  const dateFilterLabel = "Date Filter";
  const sectorLabel = "Sector";
  const {
    selectedCountries: selectedCountriesList,
    category: categoryStoreSate,
    sector: sectorStoreSate,
    dateRangeOption: storeDateRangeOption,
    fromDateRange,
    toDateRange,
  } = useSelector((state) => state.compareCountries.search);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const formik = useFormik({
    initialValues: {
      selectedCountries: [...selectedCountriesList],
      category: [...categoryStoreSate],
      sector: [...sectorStoreSate],
      dateFilter: storeDateRangeOption,
      startDate: fromDateRange ? fromDateRange : defaultAllDate,
      endDate: toDateRange ? toDateRange : new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let searchedCountryList = values.selectedCountries.map(
        (item) => item.alpha3_code
      );
      searchedCountryList = searchedCountryList.join(" ");
      dispatch({
        type: GET_COUNTRIES_STATISTICS,
        payload: {
          searchedCountryList: searchedCountryList,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
          category: values.category.join(" "),
          sector: values.sector.join(" "),
        },
      });
      dispatch({
        type: SET_SELECTED_COUNTRIES,
        payload: {
          searchedCountryList: values.selectedCountries,
          dateRangeOption: values.dateFilter,
          fromDateRange: values.startDate,
          toDateRange: values.endDate,
          category: values.category,
          sector: values.sector,
        },
      });
      setSearchAccordionState(false);
    },
  });

  const handleOnClear = () => {
    formik.resetForm();
    dispatch({ type: CLEAR_COUNTRIES_STATISCS });
  };

  const handleDateRangeOptionChange = (event, newDateRangeOption) => {
    if (newDateRangeOption === null) {
      return;
    }
    formik.setFieldValue("dateFilter", newDateRangeOption);
    switch (newDateRangeOption) {
      case "threeYear":
        formik.setFieldValue(
          "startDate",
          new Date(new Date().setDate(new Date().getDate() - 365 * 3))
        );
        formik.setFieldValue("endDate", new Date());
        break;
      case "fiveYear":
        formik.setFieldValue(
          "startDate",
          new Date(new Date().setDate(new Date().getDate() - 365 * 5))
        );
        formik.setFieldValue("endDate", new Date());
        break;

      case "custom":
        formik.setFieldValue(
          "startDate",
          new Date(
            new Date().setDate(
              new Date().getDate() - customTypeDefaultDifference
            )
          )
        );
        formik.setFieldValue("endDate", new Date());
        break;
      case "all":
        formik.setFieldValue("startDate", defaultAllDate);
        formik.setFieldValue("endDate", new Date());
        break;
      default:
        break;
    }
  };

  return (
    <Accordion
      expanded={searchAccordionState}
      sx={{ mb: 1, border: "1px solid" + theme.palette.grey[300] }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.grey[50] }} />}
        onClick={() =>
          setSearchAccordionState((currentState) => {
            return !currentState;
          })
        }
        aria-controls="search-country-compare"
        id="search-country-compare"
        sx={{
          backgroundColor: theme.palette.primary.dark,
          maxHeight: "40px",
          minHeight: "40px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.grey[50],
            fontStyle: "italic",
          }}
        >
          {"Search Countries"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            sx={{ height: "auto", width: "100%", p: 2 }}
            alignItems="center"
          >
            <Grid item xs={12} sx={{ mb: 2 }}>
              <InputLabel
                required={true}
                htmlFor="select-countries"
                sx={{
                  fontFamily: "monospace",
                  color: theme.palette.grey[900],
                  fontSize: 15,
                  mb: 1.5,
                  pl: 1,
                }}
              >
                {searchCountryLabel}
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Autocomplete
                  id="select-countries"
                  fullWidth
                  multiple
                  options={countryList}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("selectedCountries", [...newValue]);
                  }}
                  value={formik.values.selectedCountries}
                  getOptionLabel={(option) => option.country}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        size="small"
                        label={option["country"]}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.selectedCountries &&
                        Boolean(formik.errors.selectedCountries)
                      }
                      helperText={
                        formik.touched.selectedCountries &&
                        formik.errors.selectedCountries
                      }
                      sx={{
                        input: {
                          color: theme.palette.grey[900],
                          fontWeight: 500,
                        },
                      }}
                      size="small"
                      placeholder="Select country for comparision"
                    />
                  )}
                />
                <HtmlTooltip title={"Selected Country Count"}>
                  <Chip
                    size="small"
                    sx={{ ml: 1 }}
                    icon={<FlagOutlinedIcon />}
                    label={`${formik.values.selectedCountries.length}`}
                  ></Chip>
                </HtmlTooltip>
              </Box>
            </Grid>

            {/* sector */}
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputLabel
                required={true}
                htmlFor="sectorSelect"
                sx={{
                  fontFamily: "monospace",
                  color: theme.palette.grey[900],
                  fontSize: 15,
                  mb: 1.5,
                  pl: 1,
                }}
              >
                {sectorLabel}
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Autocomplete
                  multiple
                  fullWidth
                  id="sectorSelect"
                  options={Object.keys(sectorNameList)}
                  value={formik.values.sector}
                  getOptionLabel={(option) => sectorNameList[option]}
                  onChange={(event, newValue) => {
                    if (newValue === null) {
                      newValue = "";
                    }
                    formik.setFieldValue("sector", [...newValue]);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        size="small"
                        deleteIcon={
                          <CancelRoundedIcon
                            sx={{
                              color: theme.palette.error.main + "!important",
                            }}
                            fontSize="small"
                          />
                        }
                        label={sectorNameList[option]}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {sectorNameList[option]}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.sector && Boolean(formik.errors.sector)
                      }
                      helperText={formik.touched.sector && formik.errors.sector}
                      size="small"
                      placeholder="Please select Sectors"
                      sx={{
                        input: {
                          color: theme.palette.grey[900],
                          fontWeight: 500,
                        },
                      }}
                    />
                  )}
                />
                <HtmlTooltip title={"Sector Count"}>
                  <Chip
                    size="small"
                    sx={{ ml: 1 }}
                    icon={<CategoryOutlinedIcon />}
                    label={`${formik.values.sector.length}`}
                  ></Chip>
                </HtmlTooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={
                    Object.keys(sectorNameList).length ===
                    formik.values.sector.length
                  }
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      formik.setFieldValue("sector", [
                        ...Object.keys(sectorNameList),
                      ]);
                    } else {
                      formik.setFieldValue("sector", []);
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
                <Typography
                  sx={{
                    fontFamily: "monospace",
                    fontSize: 12,
                    color: theme.palette.grey[600],
                  }}
                >
                  {"Select all"}
                </Typography>
              </Box>
            </Grid>

            {/* date filter */}
            <Grid item xs={12} md={6}>
              <InputLabel
                required={true}
                htmlFor="categories"
                sx={{
                  fontFamily: "monospace",
                  color: theme.palette.grey[900],
                  fontSize: 15,
                  mb: 1.5,
                  pl: 1,
                }}
              >
                {dateFilterLabel}
              </InputLabel>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-end"
                sx={{ width: "100%", height: "auto" }}
              >
                <Grid item xs={6} sx={{ pr: 1 }}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={formik.values.dateFilter !== "custom"}
                        label="From"
                        value={dayjs(formik.values.startDate)}
                        minDate={dayjs("2012-01-01")}
                        maxDate={dayjs(formik.values.endDate)}
                        onChange={(newValue) => {
                          formik.setFieldValue("startDate", new Date(newValue));
                        }}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate),
                            helperText:
                              formik.touched.startDate &&
                              formik.errors.startDate,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ pl: 1 }}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={formik.values.dateFilter !== "custom"}
                        label="To"
                        value={dayjs(formik.values.endDate)}
                        minDate={dayjs(formik.values.startDate)}
                        maxDate={dayjs(moment(new Date()).format())}
                        onChange={(newValue) => {
                          formik.setFieldValue("endDate", new Date(newValue));
                        }}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate),
                            helperText:
                              formik.touched.endDate && formik.errors.endDate,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <ToggleButtonGroup
                    value={formik.values.dateFilter}
                    exclusive
                    onChange={handleDateRangeOptionChange}
                    aria-label="Platform"
                    sx={{
                      height: "30px",
                      width: "auto",
                      "&>.Mui-selected": {
                        background: theme.palette.primary.light,
                      },
                    }}
                  >
                    <ToggleButton fullWidth={true} value="threeYear">
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "monospace", fontWeight: 500 }}
                      >
                        3 Year
                      </Typography>
                    </ToggleButton>
                    <ToggleButton fullWidth={true} value="fiveYear">
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "monospace", fontWeight: 500 }}
                      >
                        5 Year
                      </Typography>
                    </ToggleButton>
                    <ToggleButton fullWidth={true} value="custom">
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "monospace", fontWeight: 500 }}
                      >
                        Custom
                      </Typography>
                    </ToggleButton>
                    <ToggleButton fullWidth={true} value="all">
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "monospace", fontWeight: 500 }}
                      >
                        All
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </Grid>

            {/* action */}
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2} justifyContent="flex-end">
                {selectedCountriesList.length > 0 && (
                  <Button
                    size="large"
                    variant="outlined"
                    endIcon={<BackspaceIcon />}
                    onClick={handleOnClear}
                  >
                    {"Clear Result"}
                  </Button>
                )}
                <Button
                  type="submit"
                  color="primary"
                  size="large"
                  variant="contained"
                  endIcon={<CompareArrowsOutlinedIcon />}
                >
                  {"Compare"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchMultipleCountries;

SearchMultipleCountries.propTypes = {
  countryList: PropTypes.array,
  searchAccordionState: PropTypes.bool,
  setSearchAccordionState: PropTypes.func,
};
