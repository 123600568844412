const colorPalette = [
  "#812d04",
  "#ec7015",
  "#fbb441",
  "#88af8e",
  "#1d5e60",
  "#9e9e9e",
];

export const legendList = [
  {
    name: "Very High Risk",
    color: colorPalette[0],
  },
  {
    name: "High Risk",
    color: colorPalette[1],
  },
  {
    name: "Moderate Risk",
    color: colorPalette[2],
  },
  {
    name: "Low Risk",
    color: colorPalette[3],
  },
  {
    name: "Very Low Risk",
    color: colorPalette[4],
  },
  {
    name: "Not Available",
    color: colorPalette[5],
  },
];

export const calcTierColor = (tier) => {
  let color = "#f4eadb";
  switch (tier) {
    case legendList[0].name:
      color = legendList[0].color;
      break;
    case legendList[1].name:
      color = legendList[1].color;
      break;
    case legendList[2].name:
      color = legendList[2].color;
      break;
    case legendList[3].name:
      color = legendList[3].color;
      break;
    case legendList[4].name:
      color = legendList[4].color;
      break;
    case legendList[5].name:
      color = legendList[5].color;
      break;
    default:
      color = legendList[5].color;
  }
  return color;
};
