import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Typography, useTheme } from "@mui/material";
import { HtmlTooltip } from "./HtmlToolTip";
// assets
import InfoIcon from "@mui/icons-material/Info";

const CustomInformationIcon = forwardRef(({ infoText }, ref) => {
  const theme = useTheme();
  return (
    <HtmlTooltip
      ref={ref}
      title={<Typography variant="h4">{infoText}</Typography>}
    >
      <InfoIcon
        fontSize="small"
        sx={{
          cursor: "pointer",
          height: "13px",
          color: theme.palette.grey[500],
        }}
      />
    </HtmlTooltip>
  );
});

CustomInformationIcon.propTypes = {
  infoText: PropTypes.any,
};
CustomInformationIcon.displayName = "CustomInformationIcon";
export default CustomInformationIcon;
