import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import { CountryDetailSDGGoal } from "./charts/sdgGoal";
import { CountryDetailSDGTarget } from "./charts/sdgTarget";

// assets
import sdg1 from "assets/images/risk/sdg/01.png";
import sdg2 from "assets/images/risk/sdg/02.png";
import sdg3 from "assets/images/risk/sdg/03.png";
import sdg4 from "assets/images/risk/sdg/04.png";
import sdg5 from "assets/images/risk/sdg/05.png";
import sdg6 from "assets/images/risk/sdg/06.png";
import sdg7 from "assets/images/risk/sdg/07.png";
import sdg8 from "assets/images/risk/sdg/08.png";
import sdg9 from "assets/images/risk/sdg/09.png";
import sdg10 from "assets/images/risk/sdg/10.png";
import sdg11 from "assets/images/risk/sdg/11.png";
import sdg12 from "assets/images/risk/sdg/12.png";
import sdg13 from "assets/images/risk/sdg/13.png";
import sdg14 from "assets/images/risk/sdg/14.png";
import sdg15 from "assets/images/risk/sdg/15.png";
import sdg16 from "assets/images/risk/sdg/16.png";
import sdg17 from "assets/images/risk/sdg/17.png";

import { useTranslation } from "react-i18next";
import { TitleTypography } from "ui-component/titleTypography";

export const CountryDetailSDGRecomendationTab = () => {
  const { name: countryName } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const { language, sideDrawerOpened } = useSelector(
    (state) => state.customization
  );
  const { t } = useTranslation();
  const oneLinerText = {
    en: "The 2030 Agenda for Sustainable Development, adopted by all United Nations Member States in 2015, provides a shared blueprint for peace and prosperity for people and the planet, now and into the future. At its heart are the 17 Sustainable Development Goals (SDGs), which are an urgent call for action by all countries -developed and developing - in a global partnership. They recognizethat ending poverty and other deprivations must go hand-in-hand with strategies that improve health and education, reduce inequality, and spur economic growth - all while tackling climate change and working to preserve our oceans and forests.",
    ja: "2015 年にすべての国連加盟国によって採択された持続可能な開発のための 2030 アジェンダは、現在および将来にわたって、人々と地球の平和と繁栄のための共通の青写真を提供します。 その中心にあるのは、17 の持続可能な開発目標 (SDG) です。これは、グローバル パートナーシップにおける先進国と発展途上国のすべての国による行動を緊急に求めるものです。 彼らは、貧困やその他の剥奪を終わらせるには、健康と教育を改善し、不平等を減らし、経済成長を促進する戦略が必要であることを認識しています。これらはすべて、気候変動に取り組み、海と森林を保護するために取り組んでいます。",
  };
  const iconList = [
    sdg1,
    sdg2,
    sdg3,
    sdg4,
    sdg5,
    sdg6,
    sdg7,
    sdg8,
    sdg9,
    sdg10,
    sdg11,
    sdg12,
    sdg13,
    sdg14,
    sdg15,
    sdg16,
    sdg17,
  ];
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        py: 2,
        px: {
          xs: 2,
          sm: 5,
          md: sideDrawerOpened ? 5 : 10,
          lg: sideDrawerOpened ? 10 : 20,
          xl: sideDrawerOpened ? 40 : 60,
        },
      }}
    >
      <Typography variant="h2" sx={{ color: theme.palette.primary[800] }}>
        {countryName
          ? t("SDGRecommendationTabTitleI18n") + countryName?.toUpperCase()
          : ""}
      </Typography>

      <Grid container sx={{ px: 4, height: "100%", width: "100%" }}>
        {/* one liner */}
        <Grid item xs={12} sx={{ height: "100%", width: "100%", mb: 2 }}>
          <Typography
            sx={{
              mt: 1,
              color: theme.palette.text.main,
              fontWeight: "500",
              lineHeight: "1.5rem",
            }}
            variant="h5"
          >
            {oneLinerText[language]}
          </Typography>
        </Grid>
        {/* goals */}
        <Grid item xs={12} sx={{ height: "100%", width: "100%", my: 2 }}>
          <TitleTypography title={t("SDGRecommendationGoalListTitleI18n")} />
          <Grid
            item
            xs={12}
            sx={{ height: "100%", width: "100%", mt: 1 }}
            container
          >
            {iconList?.map((sdg, idx) => {
              return (
                <Grid
                  item
                  key={idx}
                  xs={3}
                  md={3}
                  lg={2}
                  sx={{
                    mb: 3,
                    height: {
                      xs: "80px",
                      sm: "100px",
                      md: "100px",
                      lg: "120px",
                      ml: "140px",
                    },
                    display: "flex",
                    alignItems: "center",
                    displayContent: "center",
                  }}
                >
                  <img width="auto" height="100%" src={sdg} alt="not found" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* chart */}
        <Grid item xs={12} sx={{ height: "500px", width: "100%", my: 2 }}>
          <CountryDetailSDGGoal />
        </Grid>
        <Grid item xs={12} sx={{ height: "500px", width: "100%", my: 2 }}>
          <CountryDetailSDGTarget />
        </Grid>
      </Grid>
    </Box>
  );
};
