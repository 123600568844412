/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

//assets
import { useTranslation } from "react-i18next";

export const CountryChildrenEmployment = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const { child_employment: childEmployment } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );

  let yAxisName = t("childrenEmploymentYAxisNameI18n");

  useEffect(() => {
    if (childEmployment) {
      let seriesData = Object.values(childEmployment);
      setSeries([
        {
          name: yAxisName,
          data: Object.values(childEmployment),
        },
      ]),
        setOption({
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          // title: {
          //   text: `${yAxisName} =
          //   ${seriesData[seriesData.length - 1]} %
          //   `,
          //   // align: "center",
          //   style: {
          //     color: theme.palette.text.primary,
          //     // fontSize: "20px",
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          colors: [theme.palette.chart[800]],
          stroke: {
            width: 3,
            curve: "smooth",
          },
          noData: {
            text: "No Record to display",
          },
          xaxis: {
            categories: Object.keys(childEmployment),
            tickPlacement: "on",
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        });
    }
  }, [childEmployment]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="area"
          height="100%"
          width="100%"
        />
      )}
    </Box>
  );
};
