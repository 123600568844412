/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Box, Drawer, Tooltip, Typography } from "@mui/material";
// import {
//   MRT_FullScreenToggleButton,
//   MRT_ShowHideColumnsButton,
//   MRT_ToggleFiltersButton,
// } from "material-react-table";

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";

import { camel2title, capitalizeAndRemoveUnderscore } from "utils/helper";
import RiskLevelCellRenderer from "../tableView/riskLevelCellRenderer";
import SentimentCellRenderer from "../tableView/sentimentCellRenderer";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_COMPARE_COUNTRIES,
  GET_FILTERED_NGO_RECORDS,
  SET_FILTERED_NGO_RECORDS,
} from "redux/compareCountries/action";
import { NGOIssueListAndDetail } from "./ngoReportAccordion";
import { namedRequestsInProgress } from "redux/loadingAndError/selector";

const CompareCountriesPivotTableView = (props) => {
  const { columns, rowData, tableDataLoading } = props;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchData, setSearchData] = useState({
    country: "",
    indicatorClass: "",
    indicatorKey: "",
  });
  const { filteredNGORecords } = useSelector((state) => state.compareCountries);
  const { fromDateRange, toDateRange } = useSelector(
    (state) => state.compareCountries.search
  );

  let loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COMPARE_COUNTRIES,
      requestName: SET_FILTERED_NGO_RECORDS,
    })
  );

  let table = useMaterialReactTable({
    columns: columns,
    data: rowData,
    state: {
      isLoading: tableDataLoading,
      showProgressBars: tableDataLoading,
      showLoadingOverlay: false,
    },
    // muiCircularProgressProps: {
    //   color: "secondary",
    // },
    enableColumnResizing: true,
    columnResizeMode: "onEnd",
    enableGlobalFilter: false,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableGrouping: true,
    enableDensityToggle: false,
    enableFilters: false,
    enableSorting: false,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 15, estimateSize: () => 77 },
    // enableColumnVirtualization: true,
    enablePagination: false,
    enableBottomToolbar: false,

    initialState: {
      density: "compact",
      expanded: true,
      columnPinning: { left: ["indicator", "subIndicator"] },
      grouping: ["indicator"],
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    muiTablePaperProps: { sx: { height: "100%", overflow: "scroll" } },
    muiTableContainerProps: {
      sx: { maxHeight: "calc(100% - 56px) !important" },
    },
    muiTableProps: {
      sx: (theme) => ({
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        height: "100%",
      }),
    },
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: (e) => {
        if (
          ["humanRightsRisk", "supplyChainRisk"].includes(
            cell.row.original[cell.column.id].id
          ) &&
          !("groupingColumnId" in cell.row)
        ) {
          dispatch({
            type: GET_FILTERED_NGO_RECORDS,
            payload: {
              country: cell.row.original[cell.column.id].country_no,
              indicatorClass: cell.row.original[cell.column.id].id,
              indicatorKey: cell.row.original[cell.column.id].action_no,
              startDate: fromDateRange.toISOString(),
              endDate: toDateRange.toISOString(),
            },
          });
          setSearchData({
            country: cell.column.columnDef.header,
            indicatorClass: cell.row.original[cell.column.id].id,
            indicatorKey: cell.row.original.subIndicator,
          });
          setOpenDrawer(true);
        }
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    positionToolbarAlertBanner: "none",
    positionToolbarDropZone: "none",
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box sx={{ width: "700px" }}>
          <NGOIssueListAndDetail
            reportList={filteredNGORecords}
            loading={loading}
            titleText={`NGO Actions - ${camel2title(
              searchData.indicatorClass
            )}  [${searchData.indicatorKey}]  - ${searchData.country} `}
          />
        </Box>
      </Drawer>
      <MaterialReactTable table={table}></MaterialReactTable>
    </Box>
  );
};

export default CompareCountriesPivotTableView;

CompareCountriesPivotTableView.propTypes = {
  columns: PropTypes.array,
  rowData: PropTypes.array,
  tableDataLoading: PropTypes.bool,
  // table: PropTypes.any,
  // openDrawer: PropTypes.bool,
  // setOpenDrawer: PropTypes.func,
  // searchData: PropTypes.object,
};
