import * as actionTypes from "./action";

export const requestStarted = (args) => {
  const { moduleName, requestName } = args;
  return {
    type: actionTypes.REQUEST_STARTED,
    request: {
      moduleName: moduleName,
      requestName: requestName,
      inProgress: true,
    },
  };
};

export const requestFinished = (args) => {
  const { moduleName, requestName } = args;
  return {
    type: actionTypes.REQUEST_FINISHED,
    request: {
      moduleName: moduleName,
      requestName: requestName,
      inProgress: false,
    },
  };
};

export const requestFailed = (args) => {
  let { moduleName, requestName, error, severity } = args;
  if (severity === undefined) {
    severity = "error";
  }
  return {
    type: actionTypes.REQUEST_FAILED,
    request: {
      moduleName: moduleName,
      requestName: requestName,
      inProgress: false,
      error: error,
      severity: severity,
    },
  };
};

export const setInformationMessage = ({ infoMessage, infoSeverity }) => ({
  type: actionTypes.SET_INFO_MSG,
  payload: {
    notification: {
      message: infoMessage,
      severity: infoSeverity,
    },
  },
});

export const resetInformationMessage = () => ({
  type: actionTypes.SET_INFO_MSG,
  payload: {
    notification: {
      message: null,
      severity: null,
    },
  },
});
